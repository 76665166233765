/* Estilos gerais */
* {
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #1A1A1A;
  color: #333;
  line-height: 1.6;
}

.header {
  width: 100%;
  height: 70px;
  background-color: #FF8C00;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

/* Estilo do formulário */
form {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="password"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 20px;
}

/* Estilo da área de texto */
textarea {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  height: 200px;
  /* Ajuste a altura conforme necessário */
}

/* Estilo dos botões */
button {
  display: inline-block;
  background: #007bff;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background: #0056b3;
}

/* Estilos adicionais para mensagens, headers, etc. */
h1,
h2,
h3 {
  color: #FF8C00;
  ;
}

/* Mensagens de erro/sucesso */
.message {
  padding: 10px;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;
}

.success {
  background: #28a745;
}

.error {
  background: #dc3545;
}

.logo {
  width: 180px;
  height: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.menu {
  -webkit-box-align: center;
  align-items: center;
  overflow-x: hidden;
  flex: 1 1 0%;
  width: 0px;
  border-left: none;
  padding: 0px;
  display: flex;
  flex-wrap: nowrap;  
}


/* Indicação de nome do script que está sendo gerado */
.modulo {
  color: #333;
  font-size: 16px;
  font-weight: normal;
  position: relative;
  display: flex;
  flex-shrink: 0;
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  border: 0px;
  padding-right: 15px;
  padding-left: 15px;
  text-decoration: none;
  background: transparent;
}

.selecionado {
  color: #970655;
  font-weight: bold;
}

.selecionado::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: #970655;
}

.usuario {
  width: fit-content;
  padding-right: 15px;
  padding-left: 15px;
}

.main {
  padding: 20px;
}

.container {
  max-width: 800px;
  margin: auto;
  overflow: hidden;
  padding: 0 20px;
}